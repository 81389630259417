@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.sell-page {
   color: #fff;
   font-family: system-ui;
   -webkit-text-stroke: 1px #fff;
   -webkit-text-fill-color: transparent;
}
.box_shadow {
    box-shadow: 2px 2px 0px black, inset 2px 2px 0px black;
}
.box_shadow-yellow {
    box-shadow: inset 2px 2px 0px #FFDD2D;
}
.hr-index {
    z-index: -10;
}
.container-sell {
    max-width: 1157px;
    font-family: 'Inter', sans-serif;
}

@media (max-width: 1100px){
.bg_none {
    background-image: none !important;
    }
}