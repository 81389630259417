@import './variables';
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* purgecss start ignore */
@import 'shared/btn';
@import 'shared/input';
@import 'shared/switch';
@import 'shared/popup';
@import 'shared/pagy';
@import 'shared/filter_modal';
@import 'shared/scrollbar';
@import 'shared/menu';
@import 'pages/sell';

@import '../../components/noty/index';
@import 'tailwindcss/utilities';

html {
  @apply font-body;
}

body {
  @apply bg-gray-100;
  color: var(--text);
}

.container {
  max-width: 1000px;
}

.svg-icon {
  width: 25px;
  height: 25px;
}

a {
  @apply no-underline text-primary;
  &:hover {
    @apply text-dark;
  }
}

.fx-center {
  @apply flex justify-center items-center;
}

h1,
.h1 {
  @apply text-3xl font-bold text-dark leading-tight mb-6;
}

h2,
.h2 {
  @apply text-2xl font-bold leading-tight mb-6 mt-10;
}

h3,
.h3 {
  @apply text-xl font-bold leading-tight mb-6 mt-8;
}

h4,
.h4 {
  @apply text-xl font-bold leading-tight mb-3;
}

h5,
.h5 {
  @apply text-xl font-bold leading-tight mb-3;
}

.image-bg {
  background-image: var(--login-bg);
  @apply bg-fixed	bg-cover min-h-screen bg-gray-200;
}

.hidden_field {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.admin_page__body {
  min-width: 1200px;
}

.form-hint {
  position: absolute;
  top: 0;
  width: 230px;
  right: 0;
  transform: translate(110%, 0);
}

.breadcrumbs {
  a {
    @apply text-gray-500;
    &:hover {
      @apply text-primary;
    }
  }
}

div {
  appearance: none;
}

.swiper-button {
  background: none;
  transition: 0.2s;
  color: var(--yellow);
  @apply rounded-full w-12 h-12 text-sm;
  &:hover {
    background: none;
  }
  &:after {
    @apply text-2xl;
  }
}

@screen lg {
  .swiper-button {
    background: rgba(0, 0, 0, 0.4);
    transition: 0.2s;
    color: var(--yellow);
    @apply rounded-full w-12 h-12 text-sm;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
    &:after {
      @apply text-2xl;
    }

    .incard & {
      opacity: 0;
      background: none;
    }
    .incard:hover & {
      opacity: 1;
    }
  }
}

.biz-content .swiper-pagination {
  @apply w-10 flex items-center justify-center bg-white px-2;
  left: 50%;
  transform: translateX(-50%);
  .incard & {
    bottom: 10%;
  }
}

.many-slides .swiper-slide {
  width: 90%;
}
.many-slides-show .swiper-slide {
  width: 70%;
}

button:focus,
input:focus {
  outline: none;
  box-shadow: 0 0 2px var(--yellow) inset;
}

.subcat {
  @apply rounded shadow-md text-dark leading-none mr-4 px-4 py-1 bg-gray-300 mb-3 whitespace-nowrap;
  &.active {
    @apply shadow-none bg-transparent;
  }
}

input[type='search'] {
  border-radius: 0;
  -webkit-appearance: none;
}

@screen lg {
  .pos-center {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.radio {
  cursor: pointer;
  min-width: 90px;
  & input {
    opacity: 0;
    width: 0;
    position: absolute;
    height: 0;
    &:checked + div {
      background: var(--yellow-dark);
    }
  }
}

@media (max-width: 767px) {
.history-card {
  &__content {
    display: none;
  }
  &.active &__content {
    display: block;
    
  }
}
}

.trix-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px #ccc;
}

.trix-content ol {
  list-style: number;
  padding-left: 20px;
  margin-bottom: 20px;
}

.trix-content ol li {
  margin-bottom: 6px;
}

.trix-content ul {
  list-style: circle;
  margin-left: 13px;
  margin-bottom: 20px;
}

.trix-content  p {
  margin-bottom: 12px;
}

.trix-content  blockquote {
  border-left: 2px solid #fcc421;
  padding-left: 20px;
  color: #000;
  font-size: 18px;
}
.line-fix {
  position: relative;
  justify-content: center;
  &::after {
    content: '';
    position: absolute;
    background-color: #FFD841;
    width: 30%;
    height: 6px;
    flex-shrink: 0;
    bottom: 0;
    margin-bottom: -10px;
    left: 0;
    display: block;
    @media (max-width: 767.98px) {
      display: none;
    }  
  }
}
.line-down {
  position: relative;
  justify-content: center;
  &:hover, &.active {
    &::after {
      content: '';
      position: absolute;
      background-color: #FFD841;
      width: 80%;
      height: 6px;
      flex-shrink: 0;
      bottom: 0;
    }
  }
}
.video-box > iframe {
  width: 100%;
  height: 100%;
}
/* purgecss end ignore */

/* tg banner */

.banner {
  display: flex;
  background-color: #262626;
  color: #fff;
  margin-top: 30px;
  padding: 60px 40px;
  height: 400px;
}

.banner-text {
  max-width: 420px;
  margin-right: 130px;
}

.banner-title {
  font-size: 48px;
  text-transform: uppercase;
}

.banner-description {
  font-size: 18px;
  margin-bottom: 15px;
}

.banner-link {
  display: inline-block;
  padding: 15px 30px;
  background: rgb(189,240,85);
  background: linear-gradient(90deg, rgba(189,240,85,1) 0%, rgba(218,232,194,1) 66%, rgba(189,240,85,1) 100%);
  color: #262626;
  font-weight: 700;
  font-size: 20px;
}

.banner-link:hover {
  background: linear-gradient(90deg, rgba(146,191,58,1) 0%, rgba(193,223,141,1) 66%, rgba(146,184,69,1) 100%);
}

.banner-image {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
}

.banner-image img {
  max-width: 270px;
  margin-top: -110px;
}

.banner-image::before {
  content: '';
  width: 335px;
  height: 384px; 
  z-index: -1;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  border-radius: 384px;
  filter: blur(100px);
  background: #BDF055;
}

@media screen and (max-width: 768px) {
  .banner {
    overflow: hidden;
    flex-wrap: wrap;
    height: 100%;
    text-align: center;
    height: 600px;
    padding: 20px 10px;
  }
  .banner-text, .banner-image {
    margin-right: 0;
    margin: 0 auto;
  }
  .banner-text {
    margin-bottom: 60px;
  }
  .banner-title {
    font-size: 32px;
  }
  .banner-link {
    display: block;
    text-align: center;
  }
  .banner-image img {
    margin-top: -30px;
  }
}

/* modal-banner  */

.modal-banner {
  display: none;
  position: fixed;
  bottom: 0 ;
  left: 50%;
  width: 500px;
  transform:translate(-50%, 0%);
  border-radius: 10px 10px 0px 0px;
  background: #262626;
  color: #fff;
  padding: 12px 20px;
  padding-right: 100px;
  z-index: 999;
}

.modal-banner-active {
  display: block;
}

.modal-banner__title {
  font-size: 28px;
  margin: 0;
  margin-bottom: 5px;
}

.modal-banner__description {
  font-size: 13.5px;
  color: #E9E9E9;
}

.modal-banner__link {
  display: inline-flex;
  flex-direction: row-reverse;
  border-radius: 100px;
  background: rgb(189,240,85);
  background: linear-gradient(90deg, rgba(189,240,85,1) 0%, rgba(218,232,194,1) 66%, rgba(189,240,85,1) 100%);
  color: #000;
  padding: 10px 20px;
  font-weight: 700;
  transform: rotate(-21.643deg); 
  position: absolute;
  top: 0;
  right: -60px;
}

.modal-banner__link:hover {
  background: linear-gradient(90deg, rgba(146,191,58,1) 0%, rgba(193,223,141,1) 66%, rgba(146,184,69,1) 100%);
}

.modal-banner-close {
  cursor: pointer;
  position: absolute;
  left: -15px;
  top: -15px;
  background-color: #fff;
  padding: 10px;
  border-radius: 50% ;
  opacity: 0;
  transition: .6s ease-in;
}

.modal-banner-close-active {
  opacity: 1;
}

.modal-banner-close svg {
  fill: #000 !important;
  width: 15px !important;
  height: 15px !important;
}

@media screen and (max-width: 666px) {
  .modal-banner {
    width: 290px;
  }
  .modal-banner__title {
    display: none;
  }
  .modal-banner__link  {
    right: -40px;
  }
  .modal-banner__description {
    color: #E9E9E9;
    opacity: .7;
  }
  .modal-banner__description {
    font-size: 14px;
  }
}
